<template>
  <teleport v-if="news && news.mainview && news.authors && news.translations && news.translations[0].headline" to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": "{{news.translations[0].headline}}",
        "image": "{{news.mainview.file}}",
        "datePublished": "{{news.publication_date}}T{{news.created_at_time}}+02:00",
        "dateModified": "{{news.updated_at}}+02:00",
        "author": {
          "@type": "Person",
          "url": "https://kino-total.net/author/{{news.authors[0].username}}",
          "name": "{{news.authors[0].first_name}} {{news.authors[0].last_name}}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Kino-Total.net"
        }
      }
    </component>
    <link v-if="news" :href="('https://kino-total.net/news-feature/' + news.slug)" rel="canonical">
  </teleport>
  <div class="product-detail">
    <div class="teaser">
      <div v-if="e404" class="e404">
        <strong>404 Muhaahaha</strong><br/>💀<br/>
        <router-link :to="{name: 'Homepage'}">Zurück zur Homepage</router-link>
      </div>
      <div v-if="news">
        <div v-if="news.images" :style="imageStyleNews">
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="news">

      <div class="news-detail">

        <div class="news-main">
          <h2 v-if="news.translations && news.translations[0].headline">
            {{news.translations[0].headline}} <span class="tag">{{ date_format(news.publication_date) }}</span>
          </h2>

          <div class="social" v-if="news && (social_x || social_whatsapp || social_facebook)">
            <a class="twitter" v-if="social_x"
               title="Bei X empfehlen"
               :href="social_x"
               target="blank" rel="nofollow">
              X
            </a>
            <a class="facebook" v-if="social_facebook"
               title="Bei Facebook empfehlen"
               :href="social_facebook"
               target="blank" rel="nofollow">
              Facebook
            </a>
            <a class="whatsapp" v-if="social_whatsapp"
               title="Über Whatsapp empfehlen"
               :href="social_whatsapp"
               target="blank" rel="nofollow">
              WhatsApp
            </a>
          </div>

          <div v-if="news.authors" class="avatars">
            <span v-for="entry in news.authors" :key="entry.id">
              <router-link  :to="{name: 'Author', params: {username: entry.username}}">
                <img :src="entry.gravatar" alt="avatar1" />
              </router-link>
              <router-link  :to="{name: 'Author', params: {username: entry.username}}">
                {{entry.first_name}} {{entry.last_name}}
              </router-link>
              <i>Autor</i>
            </span>
            <div class="clear"></div>
          </div>

          <p v-if="news.translations && news.translations[0].description">
            <b>
              {{news.translations[0].description}}
            </b>
          </p>
          <p class="text" v-if="news.translations && news.translations[0].text" v-html="news.translations[0].text" />

          <div v-if="news.persons" class="persons">
            <p>Mehr Informationen zu:</p>
            <span v-for="entry in news.persons" :key="entry.slug">
              <router-link class="tag" :to="{name: 'Person', params: {slug: entry.slug}}">{{entry.forname}} {{entry.surname}}</router-link>
            </span>
          </div>

          <div v-if="news.videos && news.videos[0]" class="video">
            <iframe data-category="ads" width="613" height="345" :src="news.videos[0].url" :title="news.translations[0].headline" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

        </div>

        <aside class="news-aside" v-if="news.products">

          <b>Mehr zum Thema:</b>
          <div class="movies-list-small" v-if="news.products">
            <div v-for="entry in news.products" :key="entry.slug" class="entry">

                <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
                  <img v-lazy="{ src: entry.image.file }" :alt="entry.title" />
                </router-link>
                <div>
                  <h3>
                    <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
                      {{ entry.title }}
                    </router-link>
                  </h3>
                  <p>{{ entry.year }}</p>
                </div>
              <div class="clear"></div>
            </div>
          </div>

          <div v-if="positions" class="rectangle">
            <div>Werbung</div>
            <span v-for="entry in positions" :key="entry.id">
              <a :href="entry.url" target="_blank" rel=”nofollow”>
                <img v-if="entry.image" v-lazy="{ src: entry.image }" :alt="entry.name" />
              </a>
            </span>
          </div>

        </aside>
        <div class="clear"></div>
      </div>

      <div style="margin-top:20px; text-align: center; font-size: 10px;">
        Werbung<br>
        <a href="https://www.herder.de/lesen-hoeren-schenken/shop/p5/86558-die-erfindung-von-mittelerde-gebundene-ausgabe/?utm_source=kino-total.net&utm_medium=display&utm_content=banner&utm_campaign=4260-7" target="_blank"><img src="https://kino-total.net/img/4260_Garth_Erfindung_von_Mittelerde_AZ Kino-total_798x90px_Mit_logo.jpg" style="max-width:798px; width:100%; height: auto;"></a>
      </div>

      <div class="header" v-if="news.news">
        <h2>
          Weitere interessante Nachrichten
          <nav class="submenu">
            <router-link :to="{name: 'Newslist'}"> Alle Nachrichten </router-link>
            <router-link :to="{name: 'Features'}"> Alle Features </router-link>
          </nav>
        </h2>
      </div>
      <div class="news" v-if="news.news">
        <div v-for="news_item in news.news" :key="news_item.slug" class="entry">

            <router-link :to="{name: 'News', params: {slug: news_item.slug}, force: true}">
              <img class="listview" v-lazy="{ src: news_item.image }" :alt="news_item.title" />
            </router-link>
            <h3>
              <router-link :to="{name: 'News', params: {slug: news_item.slug}, force: true}">
                {{ news_item.title }}
              </router-link>
            </h3>
            <p><span class="tag">{{ date_format(news_item.publication_date) }}</span></p>
            <p class="hide-xs">{{ news_item.description }}</p>
        </div>
        <div class="clear"></div>
      </div>
      <div class="pagination">
        <div class="pagination-right">
          <router-link class="button" :to="{name: 'Newslist'}">Alle News</router-link>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div v-else class="loader">Nachricht wird geladen ...</div>
    <img :src="news.vg" v-if="news && news.vg" width="1" height="1" alt="VG" class="vgw-marker-image" loading="eager" data-no-lazy="1" referrerpolicy="no-referrer-when-downgrade" style="display:none;">
  </div>
</template>
<script>
import { dater, markdown } from '@/modules/utilities.js'
export default {
  name: 'NewsView',
  data () {
    return {
      news: '',
      url: '',
      social_x: '',
      social_facebook: '',
      social_whatsapp: '',
      positions: '',
      e404: false,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    markdown_format (data) {
      return markdown(data)
    },
    fetchData (slug) {
      fetch('https://api.kino-total.net/news/' + slug + '/', {
        method: 'GET'
      })
        .then((response) => {
          // 404
          if (response.status === 404) {
            this.e404 = true
            return
          }
          response.json().then((data) => {
            if (data.news && data.news.translations && data.news.translations[0].text) {
              if (data.news.images) {
                /* Include images in Text between paragraphs, one image per split */
                const images = data.news.images
                const imagesScenes = images.filter(image => (image.main === false && image.mainmobile === false))
                const text = data.news.translations[0].text
                const paragraphs = (text.includes('[BILD]') ? text.split('[BILD]') : text.split('\n\n'))
                const paragraphsFiltered = []
                for (let i = 0; i < paragraphs.length; i++) {
                  if (paragraphs[i].length > 0) {
                    paragraphsFiltered.push(paragraphs[i])
                  }
                }
                let i = 0
                let j = 0
                for (const paragraph of paragraphsFiltered) {
                  if (paragraph.length > 200) {
                    if (imagesScenes[i]) {
                      paragraphsFiltered[j] = paragraph + '\n' + '![' + (imagesScenes[i].credits ? imagesScenes[i].credits : '') + '](' + imagesScenes[i].file + ')'
                    }
                    i++
                  }
                  j++
                }
                data.news.translations[0].text = paragraphsFiltered.join('\n\n')
              }
              data.news.translations[0].text = this.markdown_format(data.news.translations[0].text)
            }
            this.news = data.news
            if (this.news.translations[0].description) {
              document.querySelector('head meta[name="description"]').setAttribute('content', this.news.translations[0].description)
            }
            if (this.news.translations[0].headline) {
              if (this.news.translations_others) {
                let alternateLink = document.querySelector('head link[rel="alternate"]')
                if (!alternateLink) {
                  alternateLink = document.createElement('link')
                  alternateLink.setAttribute('rel', 'alternate')
                  document.head.appendChild(alternateLink)
                }
                alternateLink.setAttribute('href', 'https://total-cinema.com/news-feature/' + this.news.slug)
              }
              document.querySelector('head title').textContent = this.news.translations[0].headline + ' - Kino-Total.net'
            }
            if (this.news && this.news.translations[0].headline) {
              this.url = 'https://kino-total.net/' + this.news.slug
              this.social_x = 'https://X.com/intent/tweet?source=webclient&amp;text=' + encodeURIComponent(this.news.translations[0].headline) + '&amp;url=' + encodeURIComponent(this.url)
              this.social_facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url)
              this.social_whatsapp = 'whatsapp://send?text=' + encodeURIComponent(this.news.translations[0].headline) + ':%20' + encodeURIComponent(this.url)
            }
            if (!this.$CookieConsent.getUserPreferences().acceptedServices.ads.includes('youtube')) {
              this.news.videos = null
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://api.kino-total.net/positions/?position=Product', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.positions = data.positions
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/news/' + slug)
    }
  },
  created () {
    this.fetchData(this.$route.params.slug)
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.news = null
        this.fetchData(toParams.slug)
        window.scrollTo(0, 0)
      }
    )
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    imageStyleNews () {
      if (this.news.mainview) {
        if (this.windowWidth > 1100) {
          return {
            backgroundImage: `url(${this.news.mainview.file})`,
            backgroundPosition: 'center top',
            backgroundSize: 'auto 100%',
            backgroundColor: 'rgb(19, 23, 34)',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '480px',
            marginTop: '-440px'
          }
        } else if (this.news.mainmobile) {
          return {
            backgroundImage: `url(${this.news.mainmobile.file})`,
            backgroundPosition: 'center top',
            backgroundSize: 'auto 100%',
            width: '100%',
            height: '480px',
            marginTop: '-440px'
          }
        }
      }
      return {
        backgroundImage: `url(${this.news.mainview.file})`,
        backgroundPosition: 'center top',
        backgroundSize: 'auto 100%',
        width: '100%',
        height: '480px',
        marginTop: '-440px'
      }
    }
  }
}
</script>
<style>

.news-detail {
  margin: 20px auto 0;
  text-align: left;
  width: 100%;
  max-width: 1000px;
  font-size: 1.1rem;
  min-height: 800px;
  padding-top: 20px;
}
.news-detail p {
  line-height: 1.5;
}

.news-main {
  float: left;
  width: 65%;
  padding: 0 20px;
}

.news-main .video {
  margin-top: 20px;
}

.news-main .text a {
  color: rgb(4, 88, 119);
  text-decoration: underline;
}

.news-aside {
  float: right;
  width: 35%;
  min-width: 350px;
  padding: 150px 20px 0 20px;
}

.news-aside p {
  font-size: 1rem;
  margin-top: 20px;
}

.news-main figure {
  margin: 20px 0 0 0;
  padding: 0;
  text-align: right;
  width: 100%;
}
.news-main figure img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
.news-main figure figcaption {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: right;
  color: #494947;
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .news-detail  {
    max-width: 100%;
  }
  .news-main {
    max-width: 500px;
    width: 100%;
    padding-right: 0;
  }
  .news-aside {
    margin-right: -60px;
    margin-top: 90px;
    min-width: 300px;
    max-width: 320px;
    width: 100%;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .news-main {
    width: 100%;
    float: none;
    padding-right: 20px;
  }
  .news-main .video iframe {
    max-height: 250px;
    height: 250px;
    margin-bottom: 100px;
  }
  .news-detail {
    padding-top: 0;
  }
  .news-aside {
    width: 100%;
    float: none;
    margin-top: 40px;
    padding-top: 0;
    padding-right: 20px;
  }
  .news-aside img {
    width: 300px;
    text-align: center;
  }
  .news-main p {
    font-size: 1rem;
  }
}
</style>
